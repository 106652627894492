<template>
    <input 
      :class="props.cl"
      type="text"
      @keyup="start"
      v-model="keyword"
      :placeholder="props.placeholder"
      /> 
</template>

<script setup>
 
import debounce from 'lodash/debounce';

const emits = defineEmits(['search'])

const props = defineProps({
  cl: {
    type: String
  },
  placeholder: {
    type: String
  },
  val: {
    type: String
  },
})

const keyword = ref(props.val);

const start = debounce((event) => {       
  console.log('start search input');     
  emits('search', keyword.value);
}, 300);


// watch(props.val, (new_val) => {
//   console.log('watch val');
//   keyword.value = new_val;
// }, {immediate: true, deep: true})



watch(() => props.val, (new_val) => {
  console.log('watch val');
  keyword.value = new_val;
})

// watchArray([props.val], ([new_val], added, removed) => {
//   console.log('watch val');
//   keyword.value = new_val;
// }, {deep: true})





</script>

<style scoped>
 
 

</style>
